.tabs {
    // width: 100%;
    .tab {
        height: 33px;
        border: solid 1px #3f51b5;
        border-left: none;
        background-color: #ffffff;
        font-size: 16px;
        color: #3f51b5;
        &.active {
            background-color: #528aea;
            color: #ffffff;
        }
        &:first-child {
            border-left: solid 1px #6c9bed;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }
        &:last-child {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }
    }
    .companyTab {
        height: 33px;
        border: solid 1px #3f51b5;
        border-left: none;
        background-color: #ffffff;
        font-size: 16px;
        color: black;
        &.active {
            background-color: #8aaeeb39;
            color: #3f51b5;
        }
        &:first-child {
            border-left: solid 1px #6c9bed;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }
        &:last-child {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }
    }
    .milestoneTab {
        height: 33px;
        border: solid 1px #3f51b5;
        background-color: #ffffff;
        font-size: 16px;
        font-weight: 600;
        color: white;
        &.active {
            // background-color: #528aea;
            color: #ffffff;
            border: solid 2px #3f51b5;
            height: 50px;
            padding: 7px !important;
        }
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
    }
    .subtab {
        height: 33px;
        border: none;
        border-bottom: solid 1px #9599a0;
        font-size: 16px;
        color: #9599a0;
        background-color: white;
        &.active {
            background-color: white;
            color: #5289ea;
            border-bottom: 4px solid;
        }
    }
}

.subtype-select {
    .tabs {
        .tab {
            padding: 0px;
            border: solid 1px #6c9bed;
            color: #528aea;
            background-color: #ffffff;
            :first-child {
                border-left: solid 1px #6c9bed;
            }
            &.active {
                background-color: #528aea;
                color: #ffffff;
            }
        } 
        
    }
}
.sub_type {
    .checkbox {
        margin-right: 25px;
    }
}