.status {
    border-radius: 7px;
    color: #fff;
    &.on-time {
        background: #24a139;
    }
    &.deviated {
        background: #000000;
    }
    &.delayed {
        background: #e26070;
    }
    &.un-tracked {
        background: #4fadfe;
    }
}