@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

.resetPasswordMain{
  padding: 0;
  margin: 0 auto;
  box-sizing: border-box;
  height: 100vh;
  // background-image: linear-gradient(to right, #597ef7, #c4befe);
  background: linear-gradient(87deg, #597EF7 15.86%, #C4BEFE 124.08%);
  position: relative
  // border: 1px solid red;
}
.resetPasswordCardMain {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  // border: 1px solid red;
}
.resetPasswordCard {
  width: 396px;
  // height: 400px;
  z-index: 2;
  background-color: #ffffff;
  border-radius: 10px;
  flex-shrink: 0;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.12);
  margin: 0 auto;
  // border: 1px solid red;
}
.resetPasswordLogo {
  // border: 1px solid red;
  // height: 90px;
  display: flex;
  justify-content: center;
}
.resetPasswordLogoImage {
  margin-top: 20px;
  width: 139px;
  height: 80px;
  margin-bottom: 30px;
  // border: 1px solid red;
}
.companyTypeButton {
  width: 312px;

  //   border: 1px solid red;
  margin-left: 42px;
  align-items: center;
}
.tabButtonStyle {
  // border: 1px solid red;
  height: 36px;
}
.resetPasswordEmailMain {
  // border: 1px solid red;
  margin-left: 42px;
  margin-top: 15px;
  width: 313px;
}
.resetPasswordEmailLable {
  // border: 1px solid red;
  font-family: "Inter";
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: #30343c;
}
.resetPasswordEmailInput {
  // border: 1px solid red;
  // width: 312px;
  height: 32px;
  // flex-shrink: 0;
  // border-radius: 4px;
  // border: 1px solid #DAE0F5;
  width: 90%;
  background: #fff;
  margin-left: 5px;
  // margin-top: ;
  border: none;
}
.resetPasswordPasswordMain {
  // border: 1px solid red;
  margin-left: 42px;
  margin-top: 15px;
  width: 313px;
}
.resetPasswordPasswordLable {
  // border: 1px solid red;
  font-family: "Inter";
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: #30343c;
}
.resetPasswordPasswordInput {
  // border: 1px solid red;
  width: 90%;
  background: #fff;
  margin-left: 5px;
  border: none;
}
.resetPasswordPasswordIcon {
  // border: 1px solid red;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  margin: 10px;
  color: #47484c;
  cursor: pointer;
}
.resetPasswordPasswordBox {
  // border: 1px solid red;
  width: 312px;
  height: 36px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid #dae0f5;
  display: flex;
  justify-content: space-between;
}
.resetPasswordEmailBox {
  // border: 1px solid red;
  width: 312px;
  height: 36px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid #dae0f5;
}
.forgotPasswordLable {
  // border: 1px solid red;
  font-family: "Inter";
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: #597ef7;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
.forgotPasswordLable:hover {
  // border: 1px solid red;
  font-family: "Inter";
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: #000000;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
.forgotPasswordLableBox {
  // border: 1px solid red;
  margin-left: 42px;
  margin-top: 15px;
  width: 313px;
  height: 18px;
}
.resetPasswordButton {
  // border: 1px solid red;
  display: flex;
  width: 312px;
  height: 40px;
  padding: 9px 0px 10px 0px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: #597ef7;
  border: none;
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  outline: none;
  cursor: pointer;
}
.resetPasswordButton:disabled {
  color: #aaa; 
  border: 1px solid #ccc; 
  background: #f5f5f5; 
  cursor: not-allowed;
}
.resetPasswordButtonBox {
  // border: 1px solid red;
  margin-left: 42px;
  margin-top: 50px;
  margin-bottom: 50px;
  width: 313px;
}
.resetPasswordWithOtpButtonBox {
  // border: 1px solid red;
  margin-left: 42px;
  margin-top: 20px;
  width: 313px;
}
.resetPasswordWithOtpButton {
  // border: 1px solid red;
  display: flex;
  width: 312px;
  height: 40px;
  padding: 9px 0px 10px 0px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  color: #597ef7;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  outline: none;
  border: 1px solid #597ef7;
  background: #fff;
  cursor: pointer;
}
.orLableBox {
  // border: 1px solid red;
  margin-left: 42px;
  margin-top: 5px;
  width: 313px;
  height: 21px;
}
.orLable {
  // border: 1px solid red;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  color: #30343c;
  display: flex;
  justify-content: center;
}
.resetPasswordWithSsoLableBox {
  // border: 1px solid red;
  margin-left: 42px;
  margin-top: 5px;
  width: 313px;
  height: 21px;
}
.resetPasswordWithSso {
  // border: 1px solid red;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  color: #597ef7;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.resetPasswordWithSso:hover {
  // border: 1px solid red;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  color: #000000;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
// .loginContainer {
  // position: relative;
  // height: 100vh;
  // display: flex;
  // justify-content: center;
  // align-items: center;
// }
.resetPasswordErrorBox{
  margin-left: 42px;
  margin-top: 20px;
  width: 313px;
  color: #cb0d38;
  // height: 21px;
}
.resetPasswordPasswordBox.box-focused {
  border-color: #3a86ff;
}
.resetPasswordButton.isnotDisable:hover{
  // border: 1px solid red;
  display: flex;
  width: 312px;
  height: 40px;
  padding: 9px 0px 10px 0px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: linear-gradient(87deg, #597EF7 15.86%, #C4BEFE 124.08%);
  border: none;
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  outline: none;
  cursor: pointer;
}