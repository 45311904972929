.trackInfoContainer {
    display: flex;
    flex-wrap: wrap;

}

.rightSide {
    background-color: inherit;
}

.leftSide {
    height: 100vh;
    min-width: 360px;
    flex: 1;
}

.leftSide > div > div {
    width: 100% !important;
    height: 100vh !important;
}