.forgotPasswordFormCardMain {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  // border: 1px solid red;
}
.forgotPasswordFormCard {
  width: 396px;
  // height: 400px;
  z-index: 2;
  background-color: #ffffff;
  border-radius: 10px;
  flex-shrink: 0;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.12);
  margin: 0 auto;
  // border: 1px solid red;
}
.forgotPasswordFormLogo {
  // border: 1px solid red;
  display: flex;
  justify-content: center;
}
.forgotPasswordLogoImage {
  margin-top: 20px;
  width: 139px;
  height: 80px;
  margin-bottom: 30px;
  //  border: 1px solid red;
}
.companyTypeButton {
  width: 312px;
  //   border: 1px solid red;
  margin-left: 42px;
  margin-top: 10px;
  align-items: center;
}
.tabButtonStyle {
  // border: 1px solid red;
  height: 36px;
}
.forgotPasswordFormEmailMain {
  // border: 1px solid red;
  margin-left: 42px;
  margin-top: 15px;
  width: 313px;
}
.forgotPasswordFormEmailLable {
  // border: 1px solid red;
  font-family: "Inter";
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: #30343c;
}
.forgotPasswordFormEmailInput {
  // border: 1px solid red;
  // width: 312px;
  height: 32px;
  // flex-shrink: 0;
  // border-radius: 4px;
  // border: 1px solid #DAE0F5;
  width: 90%;
  background: #fff;
  margin-left: 5px;
  // margin-top: ;
  border: none;
}
.forgotPasswordFormPasswordMain {
  // border: 1px solid red;
  margin-left: 42px;
  margin-top: 15px;
  width: 313px;
}
.forgotPasswordFormPasswordLable {
  // border: 1px solid red;
  font-family: "Inter";
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: #30343c;
}
.forgotPasswordFormPasswordInput {
  // border: 1px solid red;
  width: 90%;
  background: #fff;
  margin-left: 5px;
  border: none;
}
.forgotPasswordFormPasswordIcon {
  // border: 1px solid red;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  margin: 10px;
  color: #47484c;
  cursor: pointer;
}
.forgotPasswordFormPasswordBox {
  // border: 1px solid red;
  width: 312px;
  height: 36px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid #dae0f5;
  display: flex;
  justify-content: space-between;
}
.forgotPasswordFormEmailBox {
  // border: 1px solid red;
  width: 312px;
  height: 36px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid #dae0f5;
}
.forgotPasswordBackToSignInLable {
  // border: 1px solid red;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: #597ef7;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
.forgotPasswordBackToSignInLable:hover {
  // border: 1px solid red;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: #000000;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
.forgotPasswordBackToSignInLableBox {
  //   border: 1px solid red;
  margin-left: 42px;
  margin-top: 15px;
  width: 313px;
  height: 18px;
  margin-bottom: 20px;
}
.forgotPasswordButton {
  // border: 1px solid red;
  display: flex;
  width: 312px;
  height: 40px;
  padding: 9px 0px 10px 0px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: #597ef7;
  border: none;
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  outline: none;
  cursor: pointer;
}
.forgotPasswordButtonBox {
  // border: 1px solid red;
  margin-left: 42px;
  margin-top: 30px;
  width: 313px;
}
.forgotPasswordWithOtpButtonBox {
  // border: 1px solid red;
  margin-left: 42px;
  margin-top: 20px;
  width: 313px;
}
.forgotPasswordWithOtpButton {
  // border: 1px solid red;
  display: flex;
  width: 312px;
  height: 40px;
  padding: 9px 0px 10px 0px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  color: #597ef7;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  outline: none;
  border: 1px solid #597ef7;
  background: #fff;
  cursor: pointer;
}
.forgotPasswordFormEmailBox.box-focused {
  border-color: #3a86ff;
}
.forgotPasswordButton:hover {
  // border: 1px solid red;
  display: flex;
  width: 312px;
  height: 40px;
  padding: 9px 0px 10px 0px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: linear-gradient(87deg, #597EF7 15.86%, #C4BEFE 124.08%);
  border: none;
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  outline: none;
  cursor: pointer;
}