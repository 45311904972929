.wt-5{
    width: 5px;
}
.wt-10 {
    width: 10px;
}
.wt-10p {
    width: 10%;
}
.wt-20p {
    width: 20%;
}
.wt-15 {
    width: 15px;
}
.wt-20 {
    width: 20px;
}
.wt-25 {
    width: 25px;
}
.wt-30 {
    width: 30px;
}
.wt-50 {
    width: 50px;
}
.wt-60 {
    width: 60px;
}
.wt-65 {
    width: 65px;
}
.wt-70 {
    width: 70px;
}
.wt-80 {
    width: 80px;
}
.wt-100 {
    width: 100px;
}
.wt-105 {
    width: 105px;
}
.wt-110 {
    width: 110px;
}
.wt-115 {
    width: 115px;
}
.wt-125 {
    width: 125px;
}
.wt-130 {
    width: 130px;
}
.wt-140 {
    width: 140px;
}
.wt-150 {
    width: 150px;
}
.wt-165 {
    width: 165px;
}
.wt-170 {
    width: 170px;
}
.wt-175 {
    width: 175px;
}
.wt-180 {
    width: 180px;
}
.wt-190 {
    width: 190px;
}
.wt-200 {
    width: 200px;
}
.wt-122 {
    width: 122px;
}
.wt-210 {
    width: 210px;
}
.wt-225 {
    width: 225px;
}
.wt-240 {
    width: 240px;
}
.wt-250 {
    width: 250px;
}
.wt-280 {
    width: 280px;
}
.wt-290 {
    width: 290px;
}
.wt-300 {
    width: 300px;
}
.wt-315 {
    width: 315px;
}
.wt-320 {
    width: 320px;
}
.wt-350 {
    width: 350px;
}
.wt-375 {
    width: 375px;
}
.wt-400 {
    width: 400px;
}
.wt-450 {
    width: 450px;
}
.wt-500 {
    width: 500px;
}
.wt-600 {
    width: 600px;
}
.wt-700 {
    width: 700px;
}
.wt-90 {
    width: 90%;
}
.wt-7p {
    width: 7%;
}
.wt-20p {
    width: 20%;
}
.wt-50p{
    width: 50%;
}
.wt-60p{
    width: 60%;
}
.wt-80p{
    width: 80%;
}
.wt-100p{
    width: 100%;
}
.col-1 {
    width: 100%;
}
.col-1-1 {
    width: 60%;
}
.col-2 {
    width: 50%;
}
.col-3 {
    width: 33.33%
}
.col-4 {
    width: 25%;
}
.col-5 {
    width: 20%;
}
.col-6 {
    width: 16.66%;
}

.col-7 {
    width: 16.2857%;
}

.max-content {
    width: max-content;
}

.mrr-10{
    margin-right: 10rem;
}

.mwt-10 {
    max-width: 10px;
}
.mwt-15 {
    max-width: 15px;
}
.mwt-20 {
    max-width: 20px;
}
.mwt-25 {
    max-width: 25px;
}
.mwt-30 {
    max-width: 30px;
}
.mwt-50 {
    max-width: 50px;
}
.mwt-100 {
    max-width: 100px;
}
.mwt-150 {
    max-width: 150px;
}
.mwt-200 {
    max-width: 200px;
}
.mwt-250 {
    max-width: 250px;
}
.mwt-300 {
    max-width: 300px;
}
.mwt-350 {
    max-width: 350px;
}
.mwt-400 {
    max-width: 400px;
}



.ht-10 {
    height: 10px;
}
.ht-15 {
    height: 15px;
}
.ht-16 {
    height: 16px;
}
.ht-20 {
    height: 20px;
}
.ht-22 {
    height: 22px;
}
.ht-25 {
    height: 25px;
}
.ht-30 {
    height: 30px;
}
.ht-35 {
    height: 35px;
}
.ht-38 {
    height: 38px;
}
.ht-40 {
    height: 40px;
}
.ht-44 {
    height: 44px;
}
.ht-45 {
    height: 45px;
}
.ht-50 {
    height: 50px;
}
.ht-60 {
    height: 60px;
}
.ht-70 {
    height: 70px;
}
.ht-80 {
    height: 80px;
}
.ht-100 {
    height: 100px;
}
.ht-120 {
    height: 120px
}
.ht-130 {
    height: 130px
}
.ht-150 {
    height: 150px;
}
.ht-200 {
    height: 200px;
}
.ht-250 {
    height: 250px;
}
.ht-300 {
    height: 300px;
}
.ht-350 {
    height: 350px;
}
.ht-400 {
    height: 400px;
}
.ht-550 {
    height: 550px;
}
.ht-auto {
    height: fit-content;
}

.row-100 {
    height: 100%;
}

.row-32 {
    height: 32px;
}

.br-3 {
    border-radius: 3px;
}

.br-5 {
    border-radius: 5px;
}

.br-7 {
    border-radius: 7px;
}

.br-15 {
    border-radius: 15px;
}

.br-20 {
    border-radius: 20px;
}

.br-50p {
    border-radius: 50%;
}

.border-theme{
    border: 1px solid #3f51b5;
}
.border-orange-1 {
    border: solid 1px #FFA500;
};
.border-gray-1 {
    border: solid 1px #c4c9df
};
.border-gray-2 {
    border: solid 2px #c4c9df
};
.orange-bg {
    text-decoration: none;
    color: #FFA500;
    font-weight: 700;
    background-color: rgba(255, 165, 0, 0.15);
}
.bb-light {
    border-bottom: 1px solid #f1f1f5;
}
.br-light {
    border-right: 1px solid #f1f1f5;
}
.bb-theme {
    border-bottom: 1px solid #3f51b5;
}
.border-theme-light {
    border: 1px solid #d8dbf0;
}
.border-theme-light-dashed {
    border: 1px dashed #d8dbf0;
}
.bb-theme-light {
    border-bottom: 1px solid #d8dbf0;
}
.br-none {
    border: none;
}

.min-ht40 {
    min-height: 45px;
}

.wt-48p {
    width: 48%;
}

.wt-30p {
    width:30%;
}

.min-ht-200p {
    min-height: 200px;
}

.min-wt-150 {
    min-width: 150px;
}

.z-1 {
    z-index: 1;
}

.z-100 {
    z-index: 100;
}
