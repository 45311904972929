.button {
    height: 40px;
    border-radius: 5px;
    background-color: #3f51b5;
    font-size: 13px;
    font-weight: bold;
    color: #fbfbfb;
    width: 100%;
    cursor: pointer;

    &.upload {
        display: block;
        text-align: center;
        line-height: 40px;
        font-family: SFPro-Display-Medium;
    }

    .upload-button-file {
        display: none;
    }

    &.icon-button {
    color: #4051b6;
    border: none;
    background-color: transparent;
    font-weight: 600;
    font-size: 13px;
    line-height: 25px;
    font-family: inherit;

    &.icon{
        height: 20px;
    }
}

}

.share-button {
    border-radius: 5px;
    height: 40px;
    padding: 7px 9px;
    cursor: pointer;
}

.download-button {
    height: 20px !important;
}
.icon-button {
    color: #4051b6;
    border: none;
    background-color: transparent;
    font-weight: 600;
    font-size: 13px;
}
.button-classic {
    color: #4051b6;
    border: 1px solid #4051b6;
    background-color: white;
    font-weight: 600;
    font-size: 13px;
    height: 30px;
    border-radius: 5px;
    padding-left: 2px;
    padding-right: 2px;
}
