

@import 'stylekit/_list';
@import 'stylekit/_flex';
@import 'stylekit/_spacing';
@import 'stylekit/_fonts';
@import 'stylekit/_colors';
@import 'stylekit/_dimensions';

@import "components/_Logo";
@import "components/_Input";
@import "components/_Button";
@import "components/_Tabs";
@import "components/_Navbar";
@import "components/_Dropdown";
@import "components/_TripRow";
@import "components/_Sidebar";
@import "components/_Sticky";
@import "components/_TripBanner";
@import "components/_DetentionDetails";
@import "components/_Status";
@import "components/_Checkbox";
@import "components/_ActionLoader";
@import "components/_RCTimepicker";
@import "components/_Toast";
@import "components/_ResponsiveTable";
@import "components/DashboardAction";



/** Medium */
@font-face {
    font-family: "SFPro-Display-Medium";
    font-weight: 500;
    src: url("../../public/fonts/SF-UI-Display-Medium.otf");
  }

  /** Regular */
  @font-face {
    font-family: "SFPro-Display-Regular";
    font-weight: 400;
    src: url("../../public/fonts/SF-UI-Display-Regular.otf");
  }

  /** Semibold */
  @font-face {
    font-family: "SFPro-Display-Semibold";
    font-weight: 600;
    src: url("../../public/fonts/SF-UI-Display-Semibold.otf");
  }

* {
    box-sizing: border-box;
    outline: none;
}

// :not(input):not(textarea):not(button) {
//     -webkit-touch-callout: none;
//     -webkit-user-select: none;
//     -moz-user-select: none;
//     user-select: none;
//     -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);
//     -moz-tap-highlight-color: rgba(0, 0, 0, 0.1);
// }

body {
    padding: 0;
    font-family: 'SFPro-Display-Regular';
    background-color: #fff;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -moz-font-feature-settings: 'liga' on;
    font-feature-settings: 'liga' on;
    margin: 0;
}
a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
}
abbr {
    text-decoration: none;
}
input {
    border-radius: 0;
    outline: none;
}
input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
}

.header {
    color: black;
    font-size: 18px;
}

.tUnderline {
    text-decoration: underline;
}

.button {
    height: 40px;
    border-radius: 5px;
    background-color: #3f51b5;
    font-size: 13px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fbfbfb;
    border: none;
}

.tab-item {
    font-family: Roboto;
    height: 33px;
    border-radius: 5px;
    background-color: #3f51b5;
    font-size: 16px;
    color: #ffffff;
    line-height: 33px;
}

.center {
    margin: 0 auto;
}

.curP {
    cursor: pointer;
}


.current-position-icon-container {
    position: relative;
    height: 180px;
    background-image: url('../../public/images/truck.svg');
    background-repeat: no-repeat;
    background-size: 26px 29px;
    background-position-x: 10px;
    background-position-y: -2px;
}

.current-position-text {
    background-color: rgb(63, 81, 181);
    color: rgb(255, 255, 255);
    line-height: 22px;
    text-align: right;
    width: 132px;
    height: 22px;
    position: absolute;
    top: 8px;
    display: inline-block;
    border-radius: 20px;
    z-index: -1;
    padding-right: 12px;
}

.infoBox {
    transform: translateZ(0px);
    position: absolute;
    width: 200px;
    overflow: visible;
}

.route-places-info-box-container {
    background-color: #fff;
    padding: 5px;
    // border: 1px solid #3f51b5;
    text-align: center;
    width: 120px;
    height: 60px;
}

.route-places-place-name {
    font-size: 12px;
    color: #3f51b5;
    font-family: SFPro-Display-Medium;
}


.route-places-info-box-container {
    background-color: #fff;
    padding: 5px;
    // border: 1px solid #3f51b5;
    border: 1px solid #e3e3e3;
    text-align: center;
    width: 120px;
    height: 70px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
    hr {
        height: 1px;
        color: #e3e3e3;
        background-color: #e3e3e3;
        border: none;
    }

    &.destination {
        font-size: 12px;
        font-family: SFPro-Display-Medium;
        // background-color: #3f51b5;
        .route-places-place-name {
            color: #fff;
        }

    }

    &.source-destination {
        display: inline-block;
        padding: 2px;
        width: auto;
        height: auto;
    }
}


.route-places-dateTime {
    font-size: 10px;
    color: #3f51b5;
    font-family: SFPro-Display-Medium;
}

.trip-tracking-leftpane {

    padding-left: 15px;
    padding-right: 15px;

    .back-icon {
        img {
            width: 23px;
            height: 15px;
        }
    }

    header {
        margin-top: -5px;
        display: inline-block;
    }
    .overlay.share-details {
        .overlay-container {
            padding: 63px 425px 43px;
            .overlay-body {
                height: auto;
            }
        }
    }
}


.pill {
    font-size: 12px;
    border-radius: 13px;
    text-align: center;
    min-width: 72px;
    border: 1px solid #c4c9df;
    color: #5e636a;
    font-family: SFPro-Display-Regular;
    background-color: #fff;
    display: inline-block;
    margin: 5px;
    padding: 5px 10px;

    &.success {
        color: #fff;
        padding: 0px;
        background-color: #24a139;
        border-color: #24a139;
    }

    &.danger {
        color: #fff;
        padding: 0px;
        background-color: #d0021b;
        border-color: #d0021b;
    }

    &.value {
        color: #3f51b5;
        @extend .fBolder;
        background-color: rgba(63, 81, 181, 0.1);
        border: 0;
    }
}

.value-container {
    .value-text {
        color: #535356;
        @extend .fMedium;
        margin-top: 10px;
    }

    margin-top: 15px;
}

.place-details {
    .place-name {
        color: #535356;
        position: relative;
    }

    .place-date-time {
       @extend .place-name;
    }
}

.map-container {
    top: 70px;
    right: 0;
    position: fixed;
}
.map-container-share {
    top: 30px;
    right: 0;
    position: fixed;
}
.map-container-absolute {
    top: 50px;
    position: absolute;
    right: 0;
}
.button-outlined {
    background-color: rgba(63, 81, 181, 0.1);
    color: #3f51b5;
    border:1px solid #3f51b5;
}


.overlay {
    position: fixed;
    z-index: 100001;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(27, 33, 67, 0.9);

    & .overlay-container {

        padding: 56px 45px 43px;

        & .overlay-body {
            background-color: #fff;
            border-radius: 5px;
            padding: 25px 20px;
            overflow: auto;
        }

        & .overlay-close-button {
            color: #fff;
            float: right;
            margin-top: -15px;
            margin-left: 10px;
            letter-spacing: 0.2px;
            cursor: pointer;
        }
    }
}

.audit-overlay {
    position: fixed;
    z-index: 1000001;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(27, 33, 67, 0.9);

    & .audit-overlay-container {

        padding: 10px 45px 10px;
        height: 100%;

        & .audit-overlay-body {
            background-color: #fff;
            border-radius: 5px;
            padding: 25px 0px;
            overflow: auto;
            height: 100%;
        }

        & .audit-overlay-close-button {
            color: #fff;
            margin-top: -15px;
            cursor: pointer;
        }
    }
}

.detail-log-table-container {
    header {
        display: block;
        text-align: center;
    }
}

.download-logs {
    cursor: pointer;
}

.detail-log-header-controls {

    .tabs .tab {
        height: 41px;
        font-size: 12px;
        font-family: SFPro-Display-Semibold;
        font-weight: 900;
        padding-top: 7px;
        padding-bottom: 6px;
    }

    .dropdown-container {
        margin-left: 25px;
        .selected-item {
            font-size: 13px;
            color: rgba(63, 81, 181, 0.5);
            padding-top: 12px;
            padding-bottom: 12px;
            padding-left: 30px;
        }

        svg {
            width: 15px;
            height: 15px;
        }
    }
}

.detailed-view-row {
    border-bottom: 1px solid #ececee;
    position: relative;
}

.detail-log-buttons {
    .button {
        height: 29px;
        width: 120px;
        font-weight: normal;
    }
}

// Date picker styles

.DateRangePickerInput_arrow {
    display: none !important;
}

.DateInput_input__focused {
    border-bottom: 0 !important;
}
.DateRangePickerInput {
    margin-left: 20px;
}

#startDate, #endDate {
    padding: 8px 10px;
    padding-left: 40px;
    background-image: url('../../public/images/calendar.svg');
    background-repeat: no-repeat;
    font-size: 12.8px;
    font-family:  SFPro-Display-Medium;
    border: 0.9px solid #d8dbf0;
    border-radius: 5px;
    background-position-x: 10px;
    background-position-y: 12px;
}
#manualDate {
    padding: 8px 10px;
    padding-left: 40px;
    background-image: url('../../public/images/calendar.svg');
    background-repeat: no-repeat;
    font-size: 12.8px;
    font-family:  SFPro-Display-Medium;
    border: 0.9px solid #d8dbf0;
    border-radius: 5px;
    background-position-x: 10px;
    background-position-y: 12px;
}
.manual-tracking {
    .DateInput.DateInput_1 {
        width: 280px;
    }
}

.DateRangePickerInput__withBorder {
    border: 0;
}

.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
    color: #fff !important;
    background: #3f51b5 !important;
    border: 1px double #3f51b5 !important;
}

.CalendarDay__selected_span, .CalendarDay__hovered_span {
    color: #3f51b5 !important;
    background: rgba(63, 81, 181, 0.1) !important;
    border-color: rgba(63, 81, 181, 0.1) !important;
}


.DateInput_input__focused {
    border-bottom: 0.9px solid #d8dbf0 !important;
}


.data-picker {
    display: inline-block;
    width: 33%;
    .label {
        font-size: 10px;
        color: #86868a;
        //text-transform: uppercase;
        margin-bottom: 10px;
    }
}

.myCustomDate-1 {
    .react-datepicker-wrapper,
    .react-datepicker__input-container,
    .react-datepicker__input-container input {
        display: block;
        width: 180px;
        height: 30px;
        border: solid 1px #d8dbf0;
    border-radius: 5px;
    font-size: 16px;
    color: rgba(63, 81, 181, 0.5);
    }
  }

.tab-on-map {
    .tabs .tab {
        font-size: 12px;
    }
}

.add-trip-container {
    #startDate {
        border-right: 0.9px solid #d8dbf0;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        width: 238px;
    }
}

.place-detail-container {
    padding-left: 32px;
    position: relative;

    &.reached {
        position: relative;
    }

    &.first {
        margin-top: 25px;
        padding-top: 0px;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 2px;
        background:#3f51b5;
    }
    &.last::before {
        height: 50%;
    }

    &.not-reached {
        &::before{
            background:#f1f1f5;
        }
    }

    &.last-reached::before {
        height: 90%;
        background:#3f51b5;
    }

    &.last-reached::after {
        height: 70%;
        background:#f1f1f5;
        position: absolute;
        top: 90%;
        left: 0;
        width: 2px;
        content: '';
    }
}


%blue-dot {
    background:#3f51b5;
    display: block;
    top: 5px;
    position: absolute;
}

.blue-dot-md {
    width: 12px;
    height: 12px;
    border-radius: 12px;
    left: -37px;
    @extend %blue-dot;
    &.hole {
        background: #fff;
        border: 1px solid #3f51b5;
    }

}

.blue-dot-sm {
    width: 8px;
    height: 8px;
    border-radius: 8px;
    left: -35px;
    @extend %blue-dot;

    &.hole {
        background: #fff;
        width: 7px;
        height: 7px;
        border: 1px solid #3f51b5;
    }
}


.route-form-row {
    .dropdown-container {
        margin-right: 10px;
    }
}

.route-detail-search {
    padding-left: 16px !important;
}

.dropdown-select {
    width: 220px !important;
}

.trip-route-details {
    .route-detail-search {
        padding-left: 10px !important;
        &.selected-item {
            font-size: 14px;
        }
        &.tat-disable {
            padding: 0px 5px !important;
        }
    }
    .dropdown-container {
        .selected-item {
            font-size: 14px;
        }
    }
}


.add-trip-add-row-btn {
    color: #9fa8da;
    cursor: pointer;
    position: absolute;
    top: -15px;
    width: 25px;
    height: 25px;
    right: -10px;
    line-height: 22px;
    border: 1px dashed #9fa8da;
    background-color: #fff;
    padding-left: 4.5px;
    border-radius: 50%;
    z-index: 1;
    &.left {
        left: -9px;
        border: 1px solid;
        width: 9px;
        height: 9px;
        top: -6px;
        cursor: auto;
    }
}

.add-trip-add-btn-row {
    margin-bottom: 0px;
    margin-top: 13px;
    border-top: 1px dashed #d8dbf0;
    position: relative;
}

.end-row {
    margin-top: 16px;
}

.add-btn-row {
    // border-top: 1px solid red;
    margin-bottom: -10px;
    margin-top: 15px;
}

.add-trip-tracking-preference {
    .tab {
        &.flex-1 {
            flex: unset;
        }
    }
}

.flx-1{
    flex: 1;
}

.wt-180{
    width: 180px;
}
// Add trip container styles
.add-trip-container {
    .add-trip-form-label {
        color: #191717;
        text-transform: uppercase;
    }
    .date-select {
        .rc-time-picker-input {
            height: 41px;
            color: black;
            border: none;
            border-top: 0.5px solid lightgrey;
            border-right: 0.5px solid lightgrey;
            font-size: 12px;
        }
    }
    .dropdown-container {
        height: 40px;
    }
    .search-scheduler{
        border: solid 1px #d8dbf0;
        border-radius: 5px;
        width: 280px;
        height: 40px;
        input {
            border: none;
            width: 100%;
            margin-top: 5px;
        }
        
    }
    .input-container {
        input {
            font-size: 16px;
            margin-top: 2px;
        }
    }

    .high-value-shipment {
        line-height: 30px;
    }

    .add-communication-button {
        height: 53px;
        padding: 19px 51px;
        color: rgba(63, 81, 181, 0.5);
        background-color: rgba(216, 219, 240, 0.2);
        border: 1px dashed #d8dbf0;
    }

    .communication-address {
        border: 1px solid #d8dbf0;
        border-radius: 5px;
        padding: 0 15px 10px 10px;
    }

    .communication-address-type {
        color: #535356;
    }

    .route-form-row-container {
        position: relative;
        &.end-row {
            .location-side-marker {
                border-left: 1px solid #9fa8da;
                top: -30px;
                border-bottom: 1px solid #9fa8da;
                border-top: 0;
            }

            .blue {
                top: 45px;
                bottom: 0px;
            }
        }
    }

    .location-side-marker {
        position: absolute;
        width: 30px;
        height: 50px;
        left: -5px;
        border-left: 1px solid #9fa8da;
        top: 20px;
        border-top: 1px solid #9fa8da;

        .blue {
            width: 10px;
            height: 10px;
            background: #9fa8da;
            position: absolute;
            border-radius: 50%;
            top: -5px;
            left: -5px;

            &.hole {
                color: #9fa8da;
                background: #fff;
                width: 9px;
                height: 9px;
                border: 1px solid #9fa8da;
            }
        }

        &.last-in-geofence {
            height: 30px;
        }
    }

    .remove-button {
        .add-trip-add-row-btn {
            top: 5px;
            padding-left: 6px;
        }
    }

    .location-side-marker {
        &.right {
            right: 0;
            border-left: 0;
            left: 100%;
            width: 50px;
            margin-left: -50px;
        }
    }
}

.geofence {
    .location-side-marker {
        &.right {
           left: 450px;
        }
    }
    .remove-button {
        .add-trip-add-row-btn {
            top: 6px;
            padding-left: 6px;
            left: 435px;
        }
    }

    .first-in-geofence {
        display: none;
    }
}
.fixedCenter {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
}
.tooltip {
    background: #fff;
    box-shadow: 0 4px 24px 0 rgba(37, 38, 94, 0.1);
    border-radius: 5px;
}



.driver-add {
    margin-top: -40px;
    width: 30px;
    position: absolute;
    left: 870px;
    &.r-40 {
        right: 40px;
        left: unset;
    }
    &.opr-gps {
        left: 1150px;
    }
}

.driver-remove {
    @extend .driver-add;
    .opr-gps {
        left: 1150px;
    }
    .add-trip-add-row-btn {
        padding-left: 6px;
    }
}
.transition-bottom {
    transition: 0.6s bottom;
}

.m-hide {
    // @media all and (max-width: 1024px) {
    //     display: none;
    // }
}
.d-hide {
    @media all and (min-width: 1025px) {
        display: none;
    }
}

.map-select-filters {
    display: inline-block;
    width: 170px;
    margin-right: 20px;
    margin-left: 20px;
}

.cross-img {
        position: absolute;
        right: 16%;
        top: 30%;
        width: 15px;
        height: 15px;
        opacity: 0.4;
        cursor: pointer;
        background-size: contain;
        background-repeat: no-repeat;
    }

.disabled-button {
    pointer-events: none;
}

.drop-down-disable {
    background: #d0d2d4;
    color: white;
    pointer-events: none;
}

.text-break {
    word-wrap: break-word;
    hyphens: auto;
}

.share {
    &.route-details {
        .css-vj8t7z, .css-2o5izw {
            height: 38px;
            &:hover {
                border-color: hsl(0,0%,70%);
                box-shadow: none;
            }
            .css-1hwfws3 {
                overflow-y: auto;
                height: 38px;
            }
        }
    }
    .css-1wy0on6 {
        display: none;
    }
    .css-15k3avv {
        display: none;
    }
}

.gm-style img {
    max-width: 350px;
}

.display-inline {
    display: inline-block
}

.overlay.confirm-details {
    .overlay-container {
        padding: 63px 425px 43px;
        .overlay-body {
            .input-container .label {
                z-index: 0;
            }
        }
    }
}
.submit-vehicle-change {
    position: relative;
}

.submit-vehicle-change-button {
    width: 100px;
    position: relative;
    left: 45%;
}

.change-vehicle-popup {
    width: 80%;
    position: relative;
    left: 140px;

    &.modify-popup {
        left: 0;
        width:90%;
        margin: auto;
    }
}
.map-popup {
    width: 95%;
    position: relative;
}
.tracking-container{
    .map-container{
       right: unset;
    }
}
.change-vehicle-body-popup {
    width: 100%;
    overflow-y: scroll;
}
.change-driver-body-popup {
    width: 100%;
    height: 600px;
}
.create-location-popup {
    width: 100%;
    height: 450px;
}
.exception-modify-popup {
    width: 60%;
    height: 350px;
    margin-left: 280px;
}
.br253{
    border: 3px solid red;
}
.pick-time {
    position: relative;
    left: 10%;
}
.change-vehicle-button {
    width: 60%;
}
.change-driver-btn {
    margin-bottom: 10px;
    width: 12%;
}
.add-driver-button {
    width: 10%;
}
.add-driver-container {
    height: 160px;
}
.button-yes, .button-no {
    position: relative;
    right: 33%;
}
.confirm {
    position: relative;
    top: 200px;
    left: 12%;
    width: 1000px;
}
.confirm-alert {
    position: relative;
    top: 150px;
    left: 34%;
    width: 450px;
}
.border-theme-tracked-true {
    border: 2px solid green;
}
// .border-theme-tracked-false {
//     pointer-events: none;
// }
.disabled-div-true {
    pointer-events: none;
    opacity: 0.5;
}
.tracked-note {
    color: green;
}
.save-change-driver {
    width: 10%;
    position: relative;
    left: 45%;
}


.gateOutDateTime .DateInput {
    width: 200px;
    border: 1px solid #e0e0e0;
    border-radius: 3px;

    .DateInput_input {
        font-size: 14px;
        height: 40px;
        border: 5px;
    }
}

.podADD .DateInput {
    width: 150px;
    border: 1px solid black;
    border-radius: 6px;
    margin-left: 10px;
    .DateInput_input {
        font-size: 14px;
        height: 30px;
        border-radius: 6px;
    }
}

.foisDashboard .DateInput{
    width: 100px;
    .DateInput_input{
        font-size: 14px;
    }
}

.tracking-indicator-circle {
    position: relative;

    .pulsating-circle {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translateX(-50%) translateY(-50%);
          width: 15px;
          height: 15px;

          &:before {
            content: '';
            position: relative;
            display: block;
            width: 300%;
            height: 300%;
            box-sizing: border-box;
            margin-left: -100%;
            margin-top: -100%;
            border-radius: 45px;
            background-color: #01a4e9;
            animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
          }

          &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            display: block;
            width: 100%;
            height: 100%;
            background-color: #24a139;
            border-radius: 15px;
            box-shadow: 0 0 8px rgba(0,0,0,.3);
            animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite;
          }
        }

        @keyframes pulse-ring {
          0% {
            transform: scale(.33);
          }
          80%, 100% {
            opacity: 0;
          }
        }

        @keyframes pulse-dot {
          0% {
            transform: scale(.8);
          }
          50% {
            transform: scale(1);
          }
          100% {
            transform: scale(.8);
          }
        }
}


a:focus, a:hover {
    text-decoration: none;
}

.textarea {
    border: solid 1px #d8dbf0;
    height: 95px;
}

.non-selectable {
    pointer-events: none;
}

.tat-disp {
    .input-container {
        .label{
            padding-bottom: 10px;
        }
    }
}

.font-boldest{
    font-weight: 900;
}

.arrow {
    border: solid white;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
  }
  
  .right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
  
  .left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }

  .cargo-tracking {
    .serach-icon {
        g{
            stroke: #f6f6f6;
        }
    }
   .select {
       .css-1wy0on6 {
           display: none;
       }
       .css-15k3avv {
           display: none;
       }
       .css-kj6f9i-menu {
           display: none;
       }
   }
   .button{
       height: 38px;
   }
}
  .bottom {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }

  .arrow-icon {
    background-image: url('../../public/images/drop-up-arrow.svg');
    background-repeat: no-repeat;
    height: 23px;
    width: 23px;
    background-size: contain;
    margin: 0 5px;
    cursor: pointer;
    float: right;
    transition-delay: 0.2s;
    transition-duration: 0.2s;
    -webkit-transition-duration: 0.5s;
    -webkit-transition-delay: 0.2s;
  }

//   .tableFixHead { 
//     overflow: auto; 
//     height: 100vh; 
//     thead th {
//         position: sticky; 
//         top: 0; 
//         z-index: 1;
//         color: white;
//         background-color: #3f51b5;
//     }
// }

.button-top {
    width: 20% !important;
    margin: 10px;
    float: right;
}

.order-daterange {
    border: none;
    height: 30px;
    #startDate{
        padding: 8px 10px;
        padding-right: 25px;
        height: 30px;
        background-image: none !important;
        background-repeat: no-repeat;
        font-size: 12.8px;
        font-family:  SFPro-Display-Medium;
        background-position-x: 10px;
        background-position-y: 12px;
        border: 0.9px solid #d8dbf0;
        border-right: 0px solid white;
        border-bottom-right-radius: 0px;
        border-top-right-radius: 0px;
    } 
    #endDate {
        padding: 8px 10px;
        padding-right: 25px;
        background-image: url('../../public/images/calendar.svg');
        background-repeat: no-repeat;
        font-size: 12.8px;
        font-family:  SFPro-Display-Medium;
        background-position-x: 100px;
        background-position-y: 7px;
        height: 30px;
        border: 0.9px solid #d8dbf0;
        border-left: 0px solid white;
        border-bottom-left-radius: 0px;
        border-top-left-radius: 0px;
    }
    .DateRangePickerInput{
        width: max-content;
        margin-left: 0px !important;
    }
}

.table-header-details{
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
    color: #677586;
    font-size: 13px;
    text-align: center;
}
.table-body-details{
    color: #454040;
    font-size: 13px;
    padding: 10px;
    border-bottom: 2px solid #e7edfa;
    margin-left: 2px;
    margin-right: 2px;
    font-weight: 600;
    border-right: 1px solid #e7edfa;
    text-align: center;
}

.psticky {
    position: sticky;
}
.banner {
    background-color: orange;
    padding: 5px;
}
.dropdown {
    div{
        margin-right: 0px;
    }
    
}

.example::-webkit-scrollbar {
  display: none;
}

.milestoneScreenTabRow {
    section {
        margin-left: 30px;
        padding-left: 15px;
    }
    @media (max-width: 1350px) {
        section {
            margin-left: 30px;
            padding-left: 0px;
        }
    }
    @media (max-width: 1200px) {
        display: flex;
        flex-direction: column;
        section {
            margin-left: 0px;
            padding-left: 0px;
        }
    }
}

.milestone-dashboard {
    .close span {
        font-size: 26px;
    }
    .table-responsive {
        // margin-bottom: 150px;
        min-height: calc(100vh - 350px);
    }
    .table>thead:first-child>tr.bg-header>th {
        position: sticky;
        top:0;
        z-index: 3;
    }
    .table>thead:first-child>tr.bg-header>th.sticky-col {
        z-index: 4;
    }
    .table>thead:first-child>tr.bg-header>td, 
    .table>thead:first-child>tr.bg-header>th {
        background-color: #ddeeff;
        border-top: 1px solid #ddd;
        min-width: 90px;
        font-size: 12px;
    }
    .table>tbody {
        border-bottom: 1px solid #ddd;
    }
    .table>tbody>tr>td, .table>tbody>tr>th, .table>thead>tr:not(.charges-head)>th {
        border-right: 1px solid #ddd;
        min-width: 110px;
    }

    th.sticky-col, td.sticky-col {
      position:sticky;
      left:-15px;
      z-index: 2;
      background-color: #fff;
      box-shadow: 3px 0 5px -2px #888;
    }

    th.sticky-col.first-col, td.sticky-col.first-col {
        width: 90px;
        text-align: center;
        left: -30px;
        word-break: break-word;
    }

    th.sticky-col.second-col, td.sticky-col.second-col {
        width: 90px;
        left: 75px;
    }
}
@keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  .blinking-text {
    animation: blink 1s infinite;
  }
  .Toastify .Toastify__toast-container 
  {
      z-index: 999999;
  }
  .pdd-top{
    padding-top: 3px;
  }
