.pre-loader {
    position:fixed;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0, 0.5);
    z-index: 15;
  }
  .pre-loader span {
    display:block;
    bottom:0px;
    width: 9px;
    height: 5px;
    background:#9b59b6;
    position:absolute;
    top: 50%;
    left: 50%;
    -webkit-animation: preloader 1.5s  infinite ease-in-out;
            animation: preloader 1.5s  infinite ease-in-out;
  }
   
  .pre-loader span:nth-child(2) {
    left:51%;
    -webkit-animation-delay: .2s;
            animation-delay: .2s; 
  }
  .pre-loader span:nth-child(3) {
    left:52%;
    -webkit-animation-delay: .4s;
            animation-delay: .4s;
  }
  .pre-loader span:nth-child(4) {
    left:53%;
    -webkit-animation-delay: .6s;
            animation-delay: .6s;
  }
   .pre-loader span:nth-child(5){
    left:54%;
    -webkit-animation-delay: .8s;
            animation-delay: .8s;
  }
   @-webkit-keyframes preloader {
    0% {
      height:5px;
      -webkit-transform:translateY(0px);
      transform:translateY(0px);
      background:#9b59b6;
    }
    
    25% {
      height:30px;
      -webkit-transform:translateY(15px);
      transform:translateY(15px);
      background:#3498db;
    }
    
    50% {
      height:5px;
      -webkit-transform:translateY(0px);
      transform:translateY(0px);
      background:#9b59b6;
    }
     100% {
      height:5px;
      -webkit-transform:translateY(0px);
      transform:translateY(0px);
      background:#9b59b6;
    }
  }
   @keyframes preloader {
    0% {
      height:5px;
      -webkit-transform:translateY(0px);
      transform:translateY(0px);
      background:#9b59b6;
    }
     25% {
      height:30px;
      -webkit-transform:translateY(15px);
      transform:translateY(15px);
      background:#3498db;
    }
    
    50% {
      height:5px;
      -webkit-transform:translateY(0px);
      transform:translateY(0px);
      background:#9b59b6;
    }
     100% {
      height:5px;
      -webkit-transform:translateY(0px);
      transform:translateY(0px);
      background:#9b59b6;
    }
  }