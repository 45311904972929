.sticky {
    .main-page {
        // padding: 100px 0;
        padding: 50px 0 100px 70px;
    }
    @media screen and (min-width: 1025px) {
        .main-page {
            padding: 50px 0 100px 70px;
        }
    }
}