.input-container {
    height: 52px;
    .label {
        display: block;
        font-size: 13px;
        position: relative;
        top: 19px;
        transition: 0.3s top;
        z-index: -1;
        &.input-focussed {
            font-size: 10px;
            top: 0;
            transition: 0.3s top, 0.3s font-size;
        }
    }
    input {
        border: none;
        border-bottom:solid 1px #d8dbf0;
        background: transparent;
        font-size: 13px;
        font-weight: 500;
        color: #212224;
        z-index: 1;
        width: 100%;
        display: inline-block;
        box-shadow: 0 4px 24px 0 rgba(37, 38, 94, 0.1);
    }

    textarea {
        border: none;
        border-bottom:solid 1px #d8dbf0;
        background: transparent;
        font-size: 13px;
        font-weight: 500;
        color: #212224;
        z-index: 1;
        width: 100%;
        display: inline-block;
        box-shadow: 0 4px 24px 0 rgba(37, 38, 94, 0.1);
    }
    
}
.audit-input-container {
    height: 50px;
    .label {
        display: block;
        font-size: 13px;
        position: relative;
        top: 19px;
        transition: 0.3s top;
        z-index: -1;
        &.input-focussed {
            font-size: 10px;
            top: 0;
            transition: 0.3s top, 0.3s font-size;
        }
    }
    input {
        border: none;
        border:solid 1px #394eea;
        background: transparent;
        font-size: 13px;
        font-weight: 500;
        color: #212224;
        z-index: 1;
        width: 100%;
        height: 33px;
        display: inline-block;
        box-shadow: 0 4px 24px 0 rgba(37, 38, 94, 0.1);
    }

    textarea {
        border: none;
        border:solid 1px #394eea;
        background: transparent;
        font-size: 13px;
        font-weight: 500;
        color: #212224;
        z-index: 1;
        width: 100%;
        height: 33px;
        display: inline-block;
        box-shadow: 0 4px 24px 0 rgba(37, 38, 94, 0.1);
    }
    
}
.search {
    border: none;
    background: white;
    font-size: 13px;
    font-weight: 500;
    color: #212224;
    z-index: 1;
    width: 15%;
    display: inline-block;
    height: 40px;
    margin-right: 10px;
    input{
        padding-left: 2px;
    }
    .dropdown-container .selected-item {
        font-size: 14px;
        border-radius: 2px;
    }
}