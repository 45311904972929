
.sidebar {
    width: 55px;
    position: fixed;
    top: 45px;
    padding-top: 70px 0;
    height: 100vh;
    background: #3f51b5;
    z-index: 88888;
    path {
        fill: #fff;
    }
    .active {
        background: #293a93;
        border-right: 5px solid #4fadfe;
        path {
            fill:#4fadfe;
        }
    }
    .eagle-eye {
        svg {
            width: 45px;
        }
    }
}
