.default-milestone {
    .DateInput {
        margin: 0;
        padding: 0;
        background: #fff;
        position: relative;
        display: inline-block;
        width: 100px;
        vertical-align: middle;
    }

    .order-daterange {
        border: none;
        height: 30px;

        #startDate {
            padding: 6px 0px 6px 9px;
            padding-right: 14px;
            height: 38px;
            background-image: none !important;
            background-repeat: no-repeat;
            font-size: 12.8px;
            font-family: SFPro-Display-Medium;
            background-position-x: 10px;
            background-position-y: 12px;
            border: 0.9px solid #d8dbf0;
            border-right: 0px solid white;
            border-bottom-right-radius: 0px;
            border-top-right-radius: 0px;
        }

        #endDate {
            padding: 6px 0px 6px 9px;
            padding-right: 14px;
            background-image: url('../../../public/images/calendar.svg');
            background-repeat: no-repeat;
            font-size: 12.8px;
            font-family: SFPro-Display-Medium;
            background-position-x: 100px;
            background-position-y: 7px;
            height: 38px;
            border: 0.9px solid #d8dbf0;
            border-left: 0px solid white;
            border-bottom-left-radius: 0px;
            border-top-left-radius: 0px;
        }

        .DateRangePickerInput {
            width: max-content;
            margin-left: 0px !important;
        }
    }

    .t-5 {
        top: 9px;
    }

    .ht-30 {
        height: 38px;
    }
    .wr-37{
        width: 70rem;
    }
    .width-r-12{
        width: 140px !important;
    }
    .dropdown-container .selected-item {
        border: solid 1px #d8dbf0;
        border-radius: 5px;
        font-size: 14px;
        color: rgba(63, 81, 181, 0.5);
        outline: none;
    }
    .mile-dropdown{
        width: 130px !important;
    }
    .mile-input{
        width: 12rem ;
    }
}