/**
Use Sass loops to generate these classes
**/

.pr {
  position: relative;
}
.pa {
  position: absolute;
}
.pf {
  position: fixed;
}
.ps {
  position: static;
}
.psticky {
  position: sticky;
}


.mt-0 {
  margin-top: 0;
}
.mt-2 {
  margin-top: 2px;
}
.mt-3 {
  margin-top: 2px;
}
.mt-4 {
  margin-top: 4px;
}
.mt-5 {
  margin-top: 5px;
}
.mt-6 {
  margin-top: 5px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-25 {
  margin-top: 25px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-35 {
  margin-top: 35px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-50 {
  margin-top: 50px;
}
.mt-60 {
  margin-top: 60px;
}
.mt-70 {
  margin-top: 70px;
}

.mt--2 {
  margin-top: -2px;
}
.mt--3 {
  margin-top: -3px;
}
.mt--5 {
  margin-top: -5px;
}
.mt--10 {
  margin-top: -10px;
}
.mt--15 {
  margin-top: -15px;
}
.mt--20 {
  margin-top: -20px;
}
.mt--25 {
  margin-top: -25px;
}
.mt--30 {
  margin-top: -30px;
}
.mt--35 {
  margin-top: -35px;
}
.mt--40 {
  margin-top: -40px;
}
.mt--50 {
  margin-top: -50px;
}
.mt--60 {
  margin-top: -60px;
}

.mb-0 {
  margin-bottom: 0;
}
.mb-2 {
  margin-bottom: 2px;
}
.mb-3 {
  margin-bottom: 3px;
}
.mb-5 {
  margin-bottom: 5px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-25 {
  margin-bottom: 25px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-35 {
  margin-bottom: 35px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mb-80 {
  margin-bottom: 80px;
}
.mb-200 {
  margin-bottom: 200px;
}


.ml-0 {
  margin-left: 0;
}
.ml-3 {
  margin-left: 3px;
}
.ml-5 {
  margin-left: 5px;
}
.ml-10 {
  margin-left: 10px;
}
.ml-15 {
  margin-left: 15px;
}
.ml-20 {
  margin-left: 20px;
}
.ml-25 {
  margin-left: 25px;
}
.ml-30 {
  margin-left: 30px;
}
.ml-35 {
  margin-left: 35px;
}
.ml-40 {
  margin-left: 40px;
}
.ml-50 {
  margin-left: 50px;
}
.ml-55 {
  margin-left: 55px;
}
.ml-80 {
  margin-left: 80px;
}
.mr-0 {
  margin-right: 0;
}
.mr-2{
  margin-right: 2px;
}
.mr-5 {
  margin-right: 5px;
}
.mr-10 {
  margin-right: 10px !important;
}
.mr-15 {
  margin-right: 15px;
}
.mr-20 {
  margin-right: 20px;
}
.mr-25 {
  margin-right: 25px;
}
.mr-30 {
  margin-right: 30px;
}
.mr-35 {
  margin-right: 35px;
}
.mr-40 {
  margin-right: 40px;
}
.mr-50 {
  margin-right: 50px;
}
.mr-70 {
  margin-right: 70px;
}
.mr-100 {
  margin-right: 100px;
}
.mr-135 {
  margin-right: 135px;
}
.mr-0 {
  margin-right: 0;
}
.m2 {
  margin: 2px;
}
.ml-auto {
  margin-left: auto;
}
.p5 {
  padding: 5px;
}
.p10 {
  padding: 10px;
}
.p15 {
  padding: 15px;
}
.p20 {
  padding: 20px;
}
.p5-15{
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
}

.pt-0 {
  padding-top: 0;
}
.pt-2 {
  padding-top: 2px;
}
.pt-5 {
  padding-top: 5px;
}
.pt-4 {
  padding-top: 5px;
}
.pt-8 {
  padding-top: 8px;
}
.pt-10 {
  padding-top: 10px;
}
.pt-15 {
  padding-top: 15px;
}
.pt-20 {
  padding-top: 20px;
}
.pt-25 {
  padding-top: 25px;
}
.pt-30 {
  padding-top: 30px;
}
.pt-35 {
  padding-top: 35px;
}
.pt-40 {
  padding-top: 40px;
}
.pt-50 {
  padding-top: 50px;
}
.pt-80 {
  padding-top: 80px;
}

.pb-0 {
  padding-bottom: 0;
}
.pb-1 {
  padding-bottom: 1px;
}
.pb-5 {
  padding-bottom: 5px;
}
.pb-8 {
  padding-bottom: 8px;
}
.pb-10 {
  padding-bottom: 10px;
}
.pb-15 {
  padding-bottom: 15px;
}
.pb-20 {
  padding-bottom: 20px;
}
.pb-25 {
  padding-bottom: 25px;
}
.pb-30 {
  padding-bottom: 30px;
}
.pb-35 {
  padding-bottom: 35px;
}
.pb-40 {
  padding-bottom: 40px;
}
.pb-50 {
  padding-bottom: 50px;
}
.pb-80 {
  padding-bottom: 80px;
}
.pb-150 {
  padding-bottom: 150px;
}

.pl-0 {
  padding-left: 0 !important;
}
.pl-2 {
  padding-left: 2px;
}
.pl-4 {
  padding-left: 4px;
}
.pl-5 {
  padding-left: 5px;
}
.pl-8 {
  padding-left: 8px !important;
}
.pl-10 {
  padding-left: 10px;
}
.pl-15 {
  padding-left: 15px;
}
.pl-20 {
  padding-left: 20px;
}
.pl-30 {
  padding-left: 30px;
}
.pl-35 {
  padding-left: 35px;
}
.pl-40 {
  padding-left: 40px;
}

.pr-0 {
  padding-right: 0 !important;
}
.pr-2 {
  padding-right: 2px;
}
.pr-4 {
  padding-right: 4px;
}
.pr-5 {
  padding-right: 5px;
}
.pr-8 {
  padding-right: 8px !important;
}
.pr-10 {
  padding-right: 10px;
}
.pr-15 {
  padding-right: 15px;
}
.pr-20 {
  padding-right: 20px;
}
.pr-24 {
  padding-right: 24px;
}
.pr-30 {
  padding-right: 30px;
}
.pr-35 {
  padding-right: 35px;
}
.pr-40 {
  padding-right: 40px;
}
.pr-50 {
  padding-right: 50px;
}
.pr-100 {
  padding-right: 100px
}
.pl-100 {
  padding-left: 100px;
}

// Basic x-axis used for vertical
// spacing and padding from edges
.pl-16 {
  padding-left: 16px;
}
.pr-16 {
  padding-right: 16px;
}
.pl-50 {
  padding-left: 50px;
}
.ml-16 {
  margin-left: 16px;
}
.ml-100 {
  margin-left: 100px;
}
.ml-400 {
  margin-left: 400px;
}
.ml-475 {
  margin-left: 475px;
}
.ml-300 {
  margin-left: 300px;
}
.ml-750 {
  margin-left: 750px;
}
.mr-16 {
  margin-right: 16px;
}

.ml--10 {
  margin-left: -10px;
}
.mt--25 {
  margin-top: -25px;
}
.ml--35 {
  margin-left: -35px;
}
/**
 * TODO: Create variables for breakpoints
 */
@media (max-width: 320px) {
  .small\:pl-10 {
    padding-left: 10px;
  }
  .small\:pr-10 {
    padding-right: 10px;
  }
}

.mt-8 {
  margin-top: 8px;
}


.mt-15 {
  margin-top: 15px;
}

.mt-25 {
  margin-top: 25px;
}

.mb-8 {
  margin-bottom: 8px;
}


.l-0 {
  left: 0px;
}
.l-5 {
  left: 5px;
}
.l-8 {
  left: 8px;
}
.l--8 {
  left: -8px;
}
.l-10 {
  left: 10px;
}
.l-15 {
  left: 15px;
}
.l-290 {
  left: 290px;
}

.r-10 {
  right: 10px;
}
.r-20 {
  right: 20px;
}
.r-90{
  right: 90px;
}
.r-2 {
  right: 2px;
}

.t-5 {
  top: 5px;
}
.t-10 {
  top: 10px;
}

.t-5 {
  top: 5px;
}

.t-13 {
  top: 13px;
}
.t-15 {
  top: 15px;
}
.t-27 {
  top: 27px;
}
.t-100p {
  top: 100%
}
.fRight {
  float: right;
}

.fLeft {
  float: left;
}

.l--35 {
  left: -35px;
}

.t--15 {
  top: -15px;
}
.r--15 {
  right: -15px;
}
.t--13 {
  top: -13px;
}
.r--13 {
  right: -13px;
}
.b-150 {
  bottom: 150px;
}

.r-25 {
  right: 25px;
}

.r-40 {
  right: 40px;
}
.p-5 {
  padding: 5px;
}
.p-10 {
  padding: 10px;
}

.t-0{
  top: 0px;
}

.t-40{
  top: 40px;
}

.t-45{
  top: 45px;
}
