.otpVerifyFormCardMain {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  // border: 1px solid red;
}
.otpVerifyFormCard {
  width: 396px;
  // height: 550px;
  z-index: 2;
  background-color: #ffffff;
  border-radius: 10px;
  flex-shrink: 0;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.12);
  margin: 0 auto;
  // border: 1px solid red;
}
.otpVerifyFormLogo {
  // border: 1px solid red;
  display: flex;
  justify-content: center;
}
.otpVerifyLogoImage {
  margin-top: 20px;
  width: 139px;
  height: 80px;
  margin-bottom: 30px;
  // border: 1px solid red;
}
.companyTypeButton {
  width: 312px;

  //   border: 1px solid red;
  margin-left: 42px;
  align-items: center;
}
.tabButtonStyle {
  // border: 1px solid red;
  height: 36px;
}
.otpVerifyFormEmailMain {
  // border: 1px solid red;
  margin-left: 42px;
  margin-top: 15px;
  width: 313px;
}
.otpVerifyFormEmailLable {
  // border: 1px solid red;
  font-family: "Inter";
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: #30343c;
}
.otpVerifyFormEmailInput {
  // border: 1px solid red;
  // width: 312px;
  height: 32px;
  // flex-shrink: 0;
  // border-radius: 4px;
  // border: 1px solid #DAE0F5;
  width: 90%;
  background: #fff;
  margin-left: 5px;
  // margin-top: ;
  border: none;
}
.otpVerifyFormPasswordMain {
  // border: 1px solid red;
  margin-left: 42px;
  margin-top: 15px;
  width: 313px;
}
.otpVerifyFormPasswordLable {
  // border: 1px solid red;
  font-family: "Inter";
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: #30343c;
}
.otpVerifyFormPasswordInput {
  // border: 1px solid red;
  width: 90%;
  background: #fff;
  margin-left: 5px;
  border: none;
}
.otpVerifyFormPasswordIcon {
  // border: 1px solid red;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  margin: 10px;
  color: #47484c;
  cursor: pointer;
}
.otpVerifyFormPasswordBox {
  // border: 1px solid red;
  width: 312px;
  height: 36px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid #dae0f5;
  display: flex;
  justify-content: space-between;
}
.otpVerifyFormEmailBox {
  // border: 1px solid red;
  width: 312px;
  height: 36px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid #dae0f5;
}
// .forgotPasswordLable{
// // border: 1px solid red;
// font-family: 'Inter';
// font-size: 11px;
// font-style: normal;
// font-weight: 400;
// line-height: 16px;
// color:#30343C;
// display: flex;
// justify-content: flex-end;
// cursor: pointer;
// }
.forgotPasswordLableBox {
  // border: 1px solid red;
  margin-left: 42px;
  margin-top: 15px;
  width: 313px;
  height: 18px;
}
.otpVerifyButton {
  // border: 1px solid red;
  display: flex;
  width: 312px;
  height: 40px;
  padding: 9px 0px 10px 0px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: #597ef7;
  border: none;
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  outline: none;
  cursor: pointer;
}
.otpVerifyButtonBox {
  // border: 1px solid red;
  margin-left: 42px;
  margin-top: 10px;
  width: 313px;
}
.otpVerifyResendOtpButtonBox {
  // border: 1px solid red;
  margin-left: 42px;
  margin-top: 10px;
  width: 313px;
}
.otpVerifyResendOtpButton {
  // border: 1px solid red;
  display: flex;
  width: 312px;
  height: 40px;
  padding: 9px 0px 10px 0px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  color: #597ef7;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  outline: none;
  border: 1px solid #597ef7;
  background: #fff;
  cursor: pointer;
}
.otpVerifyResendOtpButton:disabled {
  color: #aaa; 
  border: 1px solid #ccc; 
  background: #f5f5f5; 
  cursor: not-allowed;
}
.otpVerifyButton:disabled {
  color: #aaa; 
  border: 1px solid #ccc; 
  background: #f5f5f5; 
  cursor: not-allowed;
}
.orLableBox {
  // border: 1px solid red;
  margin-left: 42px;
  margin-top: 5px;
  width: 313px;
  height: 21px;
}
.orLable {
  // border: 1px solid red;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  color: #30343c;
  display: flex;
  justify-content: center;
}
.otpVerifyLableBox {
  // border: 1px solid red;
  margin-left: 42px;
  margin-top: 5px;
  width: 313px;
  height: 21px;
}
.otpVerifyLable {
  // border: 1px solid red;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #262626;
  display: flex;
  justify-content: center;
  // cursor: pointer;
}
.otpvVerifyHorizonatl {
  // border: 1px solid red;
  margin-left: 42px;
  margin-top: 5px;
  width: 313px;
}
.otpVerifySendCodeBox {
  //   border: 1px solid red;
  margin-left: 42px;
  margin-top: 20px;
  width: 313px;
}
.otpVerifySendCodeLable {
  // border: 1px solid red;
  font-family: "Inter";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #262626;
  display: flex;
  justify-content: center;
  // cursor: pointer;
}
.otpVerifyInputBox {
  //   border: 1px solid rgb(0, 47, 255);
  margin-left: 42px;
  //   margin-top: 20px;
  width: 313px;
  margin-bottom: 20px;
}
.otpVerifyInput {
  border: 1px solid red;
  width: 313px;
  display: flex;
  justify-content: space-between;
}
.otpVerifyInputStyle {
  //  border: 1px solid red;
  width: 80px;
  height: 36px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid #dae0f5;
  background: #fff;
}
.otpVerifySeprator {
  //  border: 1px solid red;
  margin-left: 10px;
  margin-right: 10px;
}
.otpVerifyEnterOtpLableBox {
  //   border: 1px solid red;
  margin-left: 42px;
  margin-top: 10px;
  width: 313px;
  //   margin-bottom: 20px;
}
.otpVerifyEnterOtpLable {
  // border: 1px solid red;
  font-family: "Inter";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #262626;
}
.optVerifyTimerBox {
  //   border: 1px solid red;
  margin-left: 42px;
  margin-top: 20px;
  width: 313px;
  margin-bottom: 20px;
}
.optVerifyTimer {
  display: flex;
  justify-content: flex-end;
}
.optVerfiyBackToSignInLableBox {
  //   border: 1px solid red;
  margin-left: 42px;
  margin-top: 8px;
  width: 313px;
  height: 18px;
  margin-bottom: 20px;
}
.optVerfiyBackToSignInLable {
  // border: 1px solid red;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: #597ef7;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
.optVerfiyBackToSignInLable:hover {
  // border: 1px solid red;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: #000000;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
.maskInput{
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: #597ef7;
}
.otpVerifyButton.verifyOtp:hover {
  // border: 1px solid red;
  display: flex;
  width: 312px;
  height: 40px;
  padding: 9px 0px 10px 0px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: linear-gradient(87deg, #597EF7 15.86%, #C4BEFE 124.08%);
  border: none;
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  outline: none;
  cursor: pointer;
}
.otpVerifyResendOtpButton.verifyOtp:hover{
  display: flex;
  width: 312px;
  height: 40px;
  padding: 9px 0px 10px 0px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: linear-gradient(87deg, #597EF7 15.86%, #C4BEFE 124.08%);
  border: none;
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  outline: none;
  cursor: pointer;
}