.detention-details-table {
    border-radius: 5px;
    border: solid 1px #ececee;
    .header-container {
        background-color: #f9f9f9;
    }
    .detail-tooltip {
        display: none;
        background: #fff;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
        left:50%;
        top: 25px;
        transform: translateX(-50%);
        border-bottom: 1px solid #ececee;
        &:before{
            content:'';
            width: 0; 
            height: 0; 
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid #fff;
            position: absolute;
            top: -5px;
            left: 50%;
            transform: translateX(-50%);
            //box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
        }
    }
    .detention-detail-row-wrap {
        border-bottom: 1px solid #ececee;
        &:hover {
            .detail-tooltip {
                display: block;
            }
        }
    }
}