.trip-banner {
    .banner-item {
        border-left:2px solid #9fa8da;
        path {
            fill: #9fa8da;
        }
    }
}

.green-color {
    color: #008000;
}

.orange-color {
    color: #ffad17;
}

.consent-error-color {
    color: #600080;
}
