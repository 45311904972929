.trip-row {
    border-bottom: 1px solid #ececee;
    position: relative;
    cursor: pointer;
    &:hover {
        box-shadow: 0 4px 24px 0 rgba(63, 81, 181, 0.2);
        background: rgb(225, 237, 253);
    }
    &::after {
        content: 'Track';
        position: absolute;
        height: 24px;
        padding: 4px 24px;
        background-color: rgba(63, 81, 181, 0.1);
        color: #3f51b5;
        font-size: 13px;
        font-weight: 600;
        right: 20px;
        top: 30px;
    }
    .trip-track {
        display: flex;
    }
    // &:hover {
    //     .trip-track {
    //         display: flex;
    //     }
    //}
    .consignee-details {
        .consignee-tooltip {
            display: none;
            .detail-tooltip {
                bottom: 47px;
                background: #fff;
                box-shadow: 0 4px 24px 0 rgba(37, 38, 94, 0.1);
                border-radius: 5px;
            }
        }
        &:hover {
            .consignee-tooltip {
                display: block;
            }
        }
    }
    .driver-details {
        .driver-tooltip {
            display: none;
            .detail-tooltip {
                bottom: 47px;
                background: #fff;
                box-shadow: 0 4px 24px 0 rgba(37, 38, 94, 0.1);
                border-radius: 5px;
            }
        }
        &:hover {
            .driver-tooltip {
                display: block;
                top: 75px;
            }
        }
    }
}

.consent-status {
    width: 10px;
    height: 10px;
    border-radius: 11px;
    display: inline-block;
    margin-right: 3px;
    background-color: grey;
}

.bg-orange {
    background-color: #ffad17;
}

.bg-green {
    background-color: #008000;
}

.bg-check-consent-error {
    background: #600080 !important;
}

.sub-type {
    position: relative;
    display: inline-block;
    .tooltiptext {
        visibility: hidden;
        max-width: 160px;
        min-width: 50px;
        background-color: #fff;
        color: black;
        text-align: center;
        border-radius: 4px;
        position: absolute;
        z-index: 1;
        top: 46px;
        left: 0px;
      }
      &:hover {
        cursor: pointer;
        .tooltiptext {
            visibility: visible;
        }
      }
}

.primary-type {
    position: relative;
    display: inline-block;
    min-height: 50%;
    .tooltiptext {
        visibility: hidden;
        padding: 5px;
        max-width: 160px;
        min-width: 150px;
        background-color: #fff;
        color: black;
        text-align: center;
        border-radius: 4px;
        position: absolute;
        z-index: 1;
        top: 20px;
        left: 135px;
    }
    &:hover {
        cursor: pointer;
        .tooltiptext {
            visibility: visible;
        }
    }
}

.confirm-button {
    &:hover {
        text-decoration: underline;
    }
}

.list-action {
    max-height: 400px;
    height: 400px;
    overflow: auto;
}

.truck-action {
    &:hover {
        background: rgba(63, 81, 181, 0.1);
        font-weight: 400;
    }
}

.marker-cluster-small {
    background-color: rgba(110, 204, 57, 0.6) !important;
    }
    .marker-cluster-small div {
    background-color: rgba(110, 204, 57, 0.6) !important;
    }
    .marker-cluster-medium {
    background-color:  rgba(110, 204, 57, 0.6) !important;
    }
    .marker-cluster-medium div {
    background-color:  rgba(110, 204, 57, 0.6) !important;
    }
    .marker-cluster-large {
    background-color:  rgba(110, 204, 57, 0.6) !important;
    }
    .marker-cluster-large div {
    background-color:  rgba(110, 204, 57, 0.6) !important;
    }

.legend-status {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 3px;
    position: relative;
    background-color: #fff;
    border: 1px solid;
    .inner-circle {
        position: absolute;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        top: 1px;
        left: 1px;
    }
}


.notification-email, .notification-number {
    .email-tooltip, .number-tooltip {
        display: none;
        .detail-tooltip {
            // bottom: 47px;
            background: #fff;
            box-shadow: 0 4px 24px 0 rgba(37, 38, 94, 0.1);
            border-radius: 5px;
            z-index: 999;
        }
    }
    &:hover {
        .email-tooltip, .number-tooltip{
            display: block;
        }
    }
}
