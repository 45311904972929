.checkbox {
    margin-top: 0px;
    .outer-circle {
        height: 22px;
        width: 22px;
        border: solid 1px #d9d9d9;
        border-radius: 50%;
        position: relative;
        &.square-radius {
            border-radius: 20%;
            height: 20px;
            width: 20px;
        }
        &.square-box {
            border: solid 1px #d9d9d9;
            background-color: #fff;
        }
        .inner-circle {
            position: absolute;
            height: 12px;
            width: 12px;
            background: #3f51b5;
            border-radius: 50%;
            top: 4px;
            left: 4px;
            &.square-box {
                background: none;
                border-radius: 20%;
                top: -7px;
                left: 0px;
                &::before {
                    content: "\2713 ";
                    font-size: 24px;
                    font-weight: 800;
                    color: #3f51b5;
                }
            }
        }
    }
    .label {

    }
}

.map-checkbox {
    .outer-circle {
        background-color: #fff;
    }
    .label {
        font-size: 14px;
    }
}
