.action-table {
    position: relative;
    height: 400px;
    overflow-y: scroll;
    width: 100%;
    tr {
        padding: 5px 0px;
        border-bottom: 1px solid gray;
        td {
            padding: 5px 0px;
            text-align: center;
        }
        th {
            padding: 5px 0px;
            text-align: center;
        }
        &:hover {
            box-shadow: 0 4px 24px 0 rgba(37, 38, 94, 0.1);
        }
    }
}

.slide-in {
    z-index: 1; /* to position it in front of the other content */
    // overflow: hidden; /* to prevent scrollbar appearing */
    width: 25%
}
  
.slide-in.from-right {
right: 0;
top: 350px;
position: absolute;
}
  
.slide-in-content {
padding: 5px 20px;
background: #eee;
transition: all .5s ease; /* our nice transition */
}

.slide-in.from-right .slide-in-content {
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
}
  
.slide-in.show .slide-in-content {
    transform: translateX(0);
    -webkit-transform: translateX(0);
}

.list-style-none{
    list-style-type: none;
}

.dashboard-tab {
    .tabs{
        .tab {
            height: 28px;
        }
    }
}
.nav-item {
    .active {
        background: #3f51b5;
        color: white;
    }
}

.rotate {
    transform: rotate(180deg);
}

.action-trip {
    .place-detail-container{
        padding-top: 13px;
    &.first {
        margin-top: 10px;
        padding-top: 0px;
    }
}
    .place-details {
        .place-name {
            font-size: 12px;
        }
        .place-date-time{
            font-size: 12px;
        }   
    }
}

.action-item {
    min-height: 325px;
    vertical-align: top;
    display: inline-block;
    width: 300px;   
}