.dropdown-container {
    position: relative;
    &.ht-33 {
        height: 33px;
    }
    g, path {
        fill: none
    }
    .selected-item {
        border: solid 1px #d8dbf0;
        border-radius: 5px;
        font-size: 16px;
        color: rgba(63, 81, 181, 0.5);
        outline: none;
    }
    ul {
        padding: 0;
        margin: 0;
        position: absolute;
        top: 100%;
        border-radius: 4px;
        min-width: 100%;
        width: max-content;
        background: #fff;
        box-shadow: 0 4px 24px 0 rgba(63, 81, 181, 0.1);
        z-index: 2;
        li {
            list-style-type: none;
            font-size: 15px;
            line-height: 2.47;
            color: #686868;
            padding-right: 15px;
            cursor: pointer;
            &:hover {
                background-color: rgba(#91a1c6, 0.1);
                color: #3f51b5;
            }
        }
    }
}
.select {
    width: 250px;
}