.fs-10 {
    font-size: 10px;
}
.fs-12 {
    font-size: 12px;
}
.fs-13 {
    font-size: 13px;
}
.fs-14 {
    font-size: 14px;
}
.fs-16 {
    font-size: 16px;
}
.fs-18 {
    font-size: 18px;
}
.fs-20 {
    font-size: 20px;
}
.fs-22 {
    font-size: 22px;
}
.fs-24 {
    font-size: 24px;
}
.fs-28 {
    font-size: 28px;
}
.fs-34 {
    font-size: 34px;
}
.fs-40 {
    font-size: 40px;
}

.fBold {
    font-weight: 500;
}
.fBolder {
    font-family: SFPro-Display-Semibold;
    font-weight: 600;
}
.fMedium {
    font-family: SFPro-Display-Medium;
    font-weight: 500;
}

.tLeft {
    text-align: left;
}
.tRight {
    text-align: right;
}
.tCenter {
    text-align: center;
}

.light-font {
    color:#9fa8da !important;
}

.text-uppercase {
    text-transform: uppercase;
}
.fs-80p{
    font-size: 80%;
}
.fs-100p{
    font-size: 100%;
}
:fullscreen {
    td {
        font-size: 14px;
        font-weight: 600;
        font-family: SFPro-Display-Semibold;
    }
    div{
        opacity: 1;
    }
    tr{
        height: 5%;
    }
}

.exitBotton{
    opacity: 0;
}
.fs-italic{
    font-style: italic;
}