.theme-color {
    color: #3f51b5;
}
.theme-color-light {
    color: #6c9bed;
}
.theme-bg-color-light {
    background-color: #528aea !important;
}
.header-light-color {
    color: #ababae;
}
.table-text-color {
    color: #535356;
}
.white-color {
    color: #fff;
}
.tag-text-color {
    color: #5e636a;
}
.communication-color {
    color: #424646;
}

.communication-value-color {
    color: #212224;
}

.success-color {
    color: #24a139;
}

.error-color {
    color: #cb0d38;
}

.bg-white {
    background: #fff;
}
.box-shadow {
    box-shadow: 0 4px 24px 0 rgba(37, 38, 94, 0.1);
}
.bg-light {
    background: #f2f2f5;
}
.bg-success {
    background: #34c632;
}
.bg-error {
    background: #e15f6f;
}
.bg-success-status {
    background: #a4e6a3;
}
.bg-button-gray {
    background: #D9D9D9 !important;
}
.bg-pending {
    background: #eaea88;
}
.bgc-error{
    background: #cb0d38 !important;
}
.hover-effect {
    &:hover {
        box-shadow: 0 4px 24px 0 rgba(37, 38, 94, 0.1);
        top: -3px;
    }
}

.bg-theme {
    background: rgba(63, 81, 181, 0.1);
}

.bg-logo {
    background-color: #7548c2;
}

.op-1 {
    opacity: 0.1;
}
.op-2 {
    opacity: 0.2;
}
.op-3 {
    opacity: 0.3;
}
.op-5 {
    opacity: 0.5;
}

.bg-theme-color {
    background-color: #3f51b5;
}

.bg-light-red {
    background-color: #efdfdf;
}
.awaiting-color {
    color: #CCA429;
}
.delayed-color {
    color: #EC6263;
}
.delivered-color {
    color: #78A55A;
}