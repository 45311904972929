.navbar-wrap {
    position: fixed;
    height: 45px;
    z-index: 99999;
    background: #fff;
    border-bottom: 1px solid #f1f1f5;
    box-shadow: 0 0 27px 4px rgba(69, 68, 68, 0.2);

    g, path {
        fill: none;
    }
    input {
        border: none;
        border-bottom: 1px solid #f1f1f5;
    }
    .navbar-dropdown-items {
        position: absolute;
        top: 100%;
        width: 100%;
        background: #fff;
        left: 0;
        box-shadow: 0 4px 24px 0 rgba(37, 38, 94, 0.1);
    }
}
