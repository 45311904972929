.loginFormCardMain {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  // border: 1px solid red;
}
.loginFormCard {
  width: 396px;
  // height: 550px;
  z-index: 2;
  background-color: #ffffff;
  border-radius: 10px;
  flex-shrink: 0;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.12);
  margin: 0 auto;
  // border: 1px solid red;
}
.loginFormLogo {
  // border: 1px solid red;
  // height: 90px;
  display: flex;
  justify-content: center;
}
.loginFormLogoImage {
  margin-top: 20px;
  width: 139px;
  height: 80px;
  margin-bottom: 30px;
  // border: 1px solid red;
}
.companyTypeButton {
  width: 312px;

  //   border: 1px solid red;
  margin-left: 42px;
  align-items: center;
}
.tabButtonStyle {
  // border: 1px solid red;
  height: 36px;
}
.loginFormEmailMain {
  // border: 1px solid red;
  margin-left: 42px;
  margin-top: 15px;
  width: 313px;
}
.loginFormEmailLable {
  // border: 1px solid red;
  font-family: "Inter";
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: #30343c;
}
.loginFormEmailInput {
  // border: 1px solid red;
  // width: 312px;
  height: 32px;
  // flex-shrink: 0;
  // border-radius: 4px;
  // border: 1px solid #DAE0F5;
  width: 90%;
  background: #fff;
  margin-left: 5px;
  // margin-top: ;
  border: none;
}
.loginFormPasswordMain {
  // border: 1px solid red;
  margin-left: 42px;
  margin-top: 15px;
  width: 313px;
}
.loginFormPasswordLable {
  // border: 1px solid red;
  font-family: "Inter";
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: #30343c;
}
.loginFormPasswordInput {
  // border: 1px solid red;
  width: 90%;
  background: #fff;
  margin-left: 5px;
  border: none;
}
.loginFormPasswordIcon {
  // border: 1px solid red;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  margin: 10px;
  color: #47484c;
  cursor: pointer;
}
.loginFormPasswordBox {
  // border: 1px solid red;
  width: 312px;
  height: 36px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid #dae0f5;
  display: flex;
  justify-content: space-between;
}
.loginFormEmailBox {
  // border: 1px solid red;
  width: 312px;
  height: 36px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid #dae0f5;
}
.forgotPasswordLable {
  // border: 1px solid red;
  font-family: "Inter";
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: #597ef7;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
.forgotPasswordLable:hover {
  // border: 1px solid red;
  font-family: "Inter";
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: #000000;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
.forgotPasswordLableBox {
  // border: 1px solid red;
  margin-left: 42px;
  margin-top: 15px;
  width: 313px;
  height: 18px;
}
.loginButton {
  // border: 1px solid red;
  display: flex;
  width: 312px;
  height: 40px;
  padding: 9px 0px 10px 0px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: #597ef7;
  border: none;
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  outline: none;
  cursor: pointer;
}
.loginButtonBox {
  // border: 1px solid red;
  margin-left: 42px;
  margin-top: 10px;
  width: 313px;
}
.loginWithOtpButtonBox {
  // border: 1px solid red;
  margin-left: 42px;
  margin-top: 20px;
  width: 313px;
}
.loginWithOtpButton {
  // border: 1px solid red;
  display: flex;
  width: 312px;
  height: 40px;
  padding: 9px 0px 10px 0px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  color: #597ef7;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  outline: none;
  border: 1px solid #597ef7;
  background: #fff;
  cursor: pointer;
}
.orLableBox {
  // border: 1px solid red;
  margin-left: 42px;
  margin-top: 5px;
  width: 313px;
  height: 21px;
}
.orLable {
  // border: 1px solid red;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  color: #30343c;
  display: flex;
  justify-content: center;
}
.loginWithSsoLableBox {
  // border: 1px solid red;
  margin-left: 42px;
  margin-top: 5px;
  width: 313px;
  height: 21px;
  margin-bottom: 20px;
}
.loginWithSso {
  // border: 1px solid red;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  color: #597ef7;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.loginWithSso:hover {
  // border: 1px solid red;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  color: #000000;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
// .loginContainer {
  // position: relative;
  // height: 100vh;
  // display: flex;
  // justify-content: center;
  // align-items: center;
// }
.loginFormPasswordBox.box-focused {
  border-color: #3a86ff; 
}
.loginFormEmailBox.box-focused {
  border-color: #3a86ff;
}
.loginButton:hover {
  // border: 1px solid red;
  display: flex;
  width: 312px;
  height: 40px;
  padding: 9px 0px 10px 0px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: linear-gradient(87deg, #597EF7 15.86%, #C4BEFE 124.08%);
  border: none;
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  outline: none;
  cursor: pointer;
}
.loginWithOtpButton:hover {
  // border: 1px solid red;
  display: flex;
  width: 312px;
  height: 40px;
  padding: 9px 0px 10px 0px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: linear-gradient(87deg, #597EF7 15.86%, #C4BEFE 124.08%);
  border: none;
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  outline: none;
  cursor: pointer;
}